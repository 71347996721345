import { useMemo } from 'react';
import { format } from 'date-fns';
import { NFTMarketplaceListing } from '@app/types/nftMarketplace';

export const useGetDateRangeNftTransaction = (
  listing: NFTMarketplaceListing
) => {
  return useMemo(() => {
    if (
      !listing.start ||
      !listing.end ||
      listing.start.toString() === '0' ||
      listing.end.toString() === '0'
    ) {
      return null;
    }

    return [
      format(new Date(+listing.start * 1000), 'dd/MM/yy'),
      format(new Date(+listing.end * 1000), 'dd/MM/yy')
    ];
  }, [listing]);
};
