import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TokenSelector } from '@app/components/Swap/components/TokenSelector';
import { FormControl } from '@app/components/FormControl';
import { Token } from '@app/types/token';
import { useConfig } from '@app/config';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';

export const CurrencySection = () => {
  const config = useConfig();
  const { control } = useFormContext();

  const filterByContractAddress = (token: Token) =>
    config?.CONTENT.nftMarketplaceAllowedTokens.some(
      (allowedToken: Token) =>
        allowedToken.contractAddress.toLowerCase() ===
        token.contractAddress.toLowerCase()
    );

  return (
    <VStack width="100%" alignItems="flex-start">
      <HStack alignItems="center">
        <Text fontSize="14px" color="neutral.300">
          Select Auction currency
        </Text>
      </HStack>
      <FormControl name="currency">
        <Controller
          name="currency"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Box
              height="44px"
              backgroundColor="gray.1"
              width="fit-content"
              borderRadius="12px"
              padding="8px 12px"
            >
              <TokenSelector
                selected={value}
                onSelect={onChange}
                filterByPools={true}
                disabled={false}
                showUnverifiedCheckbox={false}
                showPopularBases={false}
                filterFn={filterByContractAddress}
              />
            </Box>
          )}
        />
      </FormControl>
    </VStack>
  );
};
