import React, { FC } from 'react';
import { AspectRatio, HStack, Text, VStack } from '@chakra-ui/react';
import { NFT } from '@thirdweb-dev/sdk';
import { formatWalletAddress } from '@app/helpers/format';
import { CopyButton } from '@app/components/CopyButton';

interface Props {
  data: NFT;
  contract: string;
}

export const TitleSection: FC<Props> = ({ data, contract }) => {
  return (
    <HStack>
      <AspectRatio
        ratio={1}
        w="100%"
        // h={120}
        pos="relative"
        overflow="hidden"
        minWidth="96px"
        borderRadius="8px"
      >
        {data.metadata.image ? (
          <img
            src={data.metadata.image}
            alt={data.metadata.name?.toString() ?? ''}
          />
        ) : (
          <div>placeholder</div>
        )}
      </AspectRatio>
      <VStack alignItems="flex-start">
        <Text fontSize={14} fontWeight={500} color="neutral.100">
          {data.metadata.name}
        </Text>
        <HStack alignItems="center" justifyContent="space-between" mt={2}>
          <Text fontSize={12} color="neutral.300">
            Contract
          </Text>
          <HStack alignItems="center" justifyContent="space-between" gap={2}>
            <Text fontSize={12} fontWeight={500} color="neutral.100">
              {formatWalletAddress(contract)}
            </Text>
            <CopyButton text={contract} />
          </HStack>
        </HStack>
        <HStack alignItems="center" justifyContent="space-between" mt={2}>
          <Text fontSize={12} color="neutral.300">
            Token Id
          </Text>
          <Text fontSize={12} fontWeight={500} color="neutral.100">
            {data.metadata.id}
          </Text>
        </HStack>
      </VStack>
    </HStack>
  );
};

export default TitleSection;
