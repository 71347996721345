import React from 'react';
import { Text } from '@chakra-ui/react';
import {
  BUY_IT_NOW_OPTION,
  BUY_IT_NOW_WITH_BIDS_OPTION
} from '@app/screens/nft-marketplace/SellNftForm';
import { useFormContext } from 'react-hook-form';

export const CancellationWarningSection = () => {
  const { watch } = useFormContext();

  const listingType = watch('listingType');

  if (
    listingType !== BUY_IT_NOW_OPTION &&
    listingType !== BUY_IT_NOW_WITH_BIDS_OPTION
  ) {
    return (
      <Text
        color="orange"
        fontWeight="500"
        fontSize="16px"
        lineHeight="18px"
        alignSelf="center"
      >
        Auction cannot be cancelled between the start and end timestamps
      </Text>
    );
  }

  return null;
};
