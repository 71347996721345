import {
  useOwnedNFTs,
  useContract,
  useAddress,
  useTokenBalance
} from '@thirdweb-dev/react';
import { useTokenInfo } from '@app/hooks/token/useTokenInfo';
import { useAsync } from 'react-use';

export function useOwnedRewardsNfts(
  contractAddress: string | undefined | null
) {
  const address = useAddress();
  const { contract } = useContract(
    contractAddress?.toLowerCase() ?? ''
    // 'nft-collection'
  );
  const { data, isLoading } = useOwnedNFTs(contract, address);

  const { value: name } = useAsync(async () => {
    if (!contract) {
      return;
    }

    return await contract.call('name');
  }, [contract]);

  return {
    name,
    data,
    isLoading
  };
}
