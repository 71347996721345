import { useMemo } from 'react';
import { NFTMarketplaceListing } from '@app/types/nftMarketplace';
import { getTokensWhitelist } from '@app/constants/tokensWhitelist';
import { useConfig } from '@app/config';

export const useFindTokenInWhitelist = (listing: NFTMarketplaceListing) => {
  const config = useConfig();

  const TOKENS_WHITELIST = getTokensWhitelist(config);

  return useMemo(() => {
    return TOKENS_WHITELIST.find(
      item =>
        item.contractAddress.toLowerCase() === listing.currency.toLowerCase()
    );
  }, [listing, TOKENS_WHITELIST]);
};
