import { Grid, GridItem, HStack, Text, VStack } from '@chakra-ui/react';
import React, { FC, useMemo } from 'react';
import { NFTMarketplaceListing } from '@app/types/nftMarketplace';
import { Token } from '@app/types/token';
import { useToggle } from 'react-use';
import { AnimatePresence, motion } from 'framer-motion';
import { format } from 'date-fns';
import { getTypeAcronym } from '@app/screens/nft-marketplace/SpNftMarketPlaceList/SprNftMarketplaceCell/helpers';
import { TokensPair } from '@app/components/TokensPair';
import { Payment } from '@app/components/TransactionNftMarketplaceHistoryItem/Payment';
import { ExternalLink } from '@app/components/ExternalLink';
import { formatWalletAddress } from '@app/helpers/format';
import { CopyButton } from '@app/components/CopyButton';
import { ViewFilter } from '@app/hooks/history/useTransactionHistoryNFT';
import { useFindTokenInWhitelist } from '@app/hooks/nft/useFindTokenInWhitelist';
import { TransactionNftDetails } from '@app/components/TransactionNftDetails';
import { TransactionNftHistoryTimestamp } from '@app/components/TransactionNftHistoryTimestamp';

type Prop = {
  listing: NFTMarketplaceListing;
  selectedView: ViewFilter;
};

export const TransactionNftHistoryItem: FC<Prop> = ({
  listing,
  selectedView
}) => {
  const {
    tokenId,
    payment,
    token0,
    token1,
    purchaseTxHash,
    purchaseTimestamp
  } = listing;

  const [openDetails, toggleOpen] = useToggle(false);

  const token = useFindTokenInWhitelist(listing);

  return (
    <Grid
      onClick={() => toggleOpen()}
      width="100%"
      borderRadius="8px"
      backgroundColor="gray.10"
      justifyItems="center"
      padding="8px 8px"
      templateColumns=".2fr .3fr .2fr .45fr .5fr"
      alignItems="center"
      cursor="pointer"
      gap={1}
    >
      <GridItem>
        <TokensPair
          size="sm"
          token0Symbol={token0.symbol}
          token1Symbol={token1.symbol}
        />
      </GridItem>
      <GridItem>
        <VStack alignItems="flex-start" gap={1}>
          <Text fontSize="12px" lineHeight="12px" color="white">
            {token0.symbol}-{token1.symbol}
          </Text>
          <Text fontSize="12px" color="gray.4">
            ID: {tokenId}
          </Text>
        </VStack>
      </GridItem>
      <GridItem>
        <Text fontSize="12px" color="gray.4">
          {getTypeAcronym(listing.type)}
        </Text>
      </GridItem>
      <GridItem width="100%" alignItems="start">
        {token && <Payment currency={token} value={payment} />}
      </GridItem>
      <GridItem width="100%">
        <TransactionNftHistoryTimestamp
          purchaseTimestamp={purchaseTimestamp}
          selectedView={selectedView}
        />
      </GridItem>
      <AnimatePresence>
        {openDetails ? (
          <TransactionNftDetails purchaseTxHash={purchaseTxHash} />
        ) : null}
      </AnimatePresence>
    </Grid>
  );
};
