import { HStack, Text, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';
import { motion } from 'framer-motion';
import { formatWalletAddress } from '@app/helpers/format';
import { CopyButton } from '@app/components/CopyButton';
import { ExternalLink } from '@app/components/ExternalLink';
import { NFTMarketplaceListing } from '@app/types/nftMarketplace';

type Prop = {
  purchaseTxHash: string;
};

const sharedMotionProps = {
  initial: { opacity: 0, height: 0 },
  animate: { opacity: 1, height: 'auto' },
  exit: { opacity: 0, height: 0 },
  transition: { duration: 0.3, ease: 'easeInOut' }
};

export const TransactionNftDetails: FC<Prop> = ({ purchaseTxHash }) => {
  return (
    <motion.div
      layout
      style={{ gridColumn: '1 / -1', overflow: 'hidden', width: '100%' }}
      {...sharedMotionProps}
    >
      <VStack alignItems="flex-start" width="100%" padding="8px">
        <HStack width="100%" justifyContent="space-between">
          <Text fontSize="12px" lineHeight="12px" color="whiteOpacity.50">
            Transaction hash:
          </Text>
          <HStack>
            <Text fontSize="12px" lineHeight="12px" color="whiteOpacity.50">
              {formatWalletAddress(purchaseTxHash)}
            </Text>
            <CopyButton text={purchaseTxHash} />
          </HStack>
        </HStack>
        <ExternalLink txHash={purchaseTxHash} />
      </VStack>
    </motion.div>
  );
};
