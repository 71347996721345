import React, { FC } from 'react';
import {
  KpNftDetails,
  PositionItemContext,
  SpNftModalRowItemProp
} from '@app/screens/nft-marketplace/SpNftOffersModal/PositionContext/PositionItemContext';
import { useToggle } from 'react-use';
import { usePositionKpNftDetailedData } from '@app/hooks/positions/usePositionKpNftDetailedData';
import { useGetIsPremiumNft } from '@app/hooks/nft/useGetIsPremiumNft';
import { Box, Grid, GridItem, HStack, Text, VStack } from '@chakra-ui/react';
import { PremiumNftBadge } from '@app/screens/nft-marketplace/PremiumNftBadge';
import { LogoSection } from '@app/screens/positions/components/SpNftPositionListRowItem/components/LogoSection';
import { SymbolsSection } from '@app/screens/positions/components/SpNftPositionListRowItem/components/SymbolsSection';
import { StrategySection } from '@app/components/StrategySection';
import { AmountSection } from '@app/screens/positions/components/SpNftPositionListRowItem/components/AmountSection';
import { PropertiesSection } from '@app/screens/positions/components/SpNftPositionListRowItem/components/PropertiesSection';
import { ListNftButton } from '@app/screens/nft-marketplace/ListNftButton';
import { SellNftModal } from '@app/screens/nft-marketplace/SellNftModal';

export const NftMarketplaceMenuPositionItem: FC<
  SpNftModalRowItemProp & { isMobile?: boolean }
> = ({
  poolAddress,
  tokenId,
  tokenDetails,
  boosterView,
  isMobile = false,
  isStakedToNitro,
  nitroPoolId
}) => {
  const [isListNftModalOpen, toggleIsListNftModalOpen] = useToggle(false);
  const handleListNftButtonClick = () => {
    toggleIsListNftModalOpen();
  };

  let kpNftDetails: KpNftDetails = usePositionKpNftDetailedData(
    poolAddress,
    tokenId,
    tokenDetails,
    isStakedToNitro,
    nitroPoolId
  );

  let position = {
    tokenId,
    poolAddress,
    tokenDetails,
    isStakedToNitro,
    nitroPoolId,
    ...kpNftDetails
  };

  let { data, vault, lockDetails, usdAmount } = position;

  const { data: isPremiumNft } = useGetIsPremiumNft(poolAddress, tokenId);

  if (!data || !tokenDetails) {
    return null;
  }

  if (!vault) {
    return null;
  }

  if (!lockDetails.isLocked) {
    return null;
  }

  return (
    <>
      {isMobile ? (
        <VStack
          gap={3}
          width="100%"
          border="1px solid"
          borderColor="gray.5"
          p="10px 14px"
          align="center"
          spacing="16px"
          mb="14px"
          transition="background-color 0.2s ease"
          borderRadius="16px"
          marginTop="16px"
        >
          <HStack
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            {isPremiumNft ? <PremiumNftBadge /> : null}
            <LogoSection vault={vault} tokenDetails={tokenDetails} />
            <SymbolsSection vault={vault} tokenDetails={tokenDetails} />
            <Text fontSize="14px" color="gray.4">
              ID: {tokenId}
            </Text>
          </HStack>
          <HStack
            alignItems="flex-start"
            justifyContent="space-between"
            width="100%"
          >
            <Text fontSize="14px" color="gray.4">
              Strategy
            </Text>
            <StrategySection vault={vault} />
          </HStack>
          <HStack
            alignItems="flex-start"
            justifyContent="space-between"
            width="100%"
          >
            <Text fontSize="14px" color="gray.4">
              Amount
            </Text>
            <AmountSection amount={data.amount} usdAmount={usdAmount} />
          </HStack>
          <HStack alignItems="flex-start" width="100%">
            <PropertiesSection
              lockDetails={lockDetails}
              poolAddress={poolAddress}
              tokenId={tokenId}
              isStakedToNitro={isStakedToNitro}
            />
          </HStack>
          <HStack width="100%">
            <ListNftButton
              isMobile={true}
              onClick={handleListNftButtonClick}
              isPremium={isPremiumNft}
            />
          </HStack>
        </VStack>
      ) : (
        <Grid
          width="100%"
          borderRadius="12px"
          sx={{
            border: '1px solid',
            borderColor: isPremiumNft ? 'premium' : 'whiteOpacity.6',
            mb: '8px',
            backgroundColor: isPremiumNft ? 'premiumOpacity.1' : 'transparent'
          }}
          _hover={{
            backgroundColor: 'neutral.800',
            cursor: 'pointer'
          }}
          justifyItems="center"
          padding="4px 12px"
          templateColumns="1.5fr repeat(4, 1fr)"
          alignItems="center"
          gap={1}
        >
          <GridItem width="100%">
            <HStack>
              <HStack>
                {isPremiumNft ? <PremiumNftBadge /> : <Box width="24px" />}
                <LogoSection vault={vault} tokenDetails={tokenDetails} />
                <VStack alignItems="flex-start" gap={1}>
                  <SymbolsSection
                    fontSize="14px"
                    vault={vault}
                    tokenDetails={tokenDetails}
                  />
                  <Text fontSize="12px" color="gray.4">
                    ID: {tokenId}
                  </Text>
                </VStack>
              </HStack>
            </HStack>
          </GridItem>
          <GridItem width="100%">
            <StrategySection vault={vault} sx={{ alignItems: 'center' }} />
          </GridItem>
          <GridItem width="100%">
            <PropertiesSection
              lockDetails={lockDetails}
              poolAddress={poolAddress}
              tokenId={tokenId}
              isStakedToNitro={isStakedToNitro}
            />
          </GridItem>
          <GridItem width="100%">
            <AmountSection amount={data.amount} usdAmount={usdAmount} />
          </GridItem>
          <GridItem width="100%" display="flex" justifyContent="flex-end">
            <ListNftButton
              onClick={handleListNftButtonClick}
              isPremium={isPremiumNft}
            />
          </GridItem>
        </Grid>
      )}
      <PositionItemContext.Provider value={position}>
        <SellNftModal
          isOpen={isListNftModalOpen}
          onClose={() => toggleIsListNftModalOpen(false)}
        />
      </PositionItemContext.Provider>
    </>
  );
};
