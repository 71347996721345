import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  BUY_IT_NOW_OPTION,
  RESERVE_TOOLTIP_MESSAGE
} from '@app/screens/nft-marketplace/SellNftForm';
import styles from '@app/screens/nft-marketplace/SellNftForm/SellNftForm.module.scss';
import { FormControl } from '@app/components/FormControl';
import { NftPriceInput } from '@app/screens/nft-marketplace/SellNftForm/NftPriceInput';
import { Tooltip } from '@app/components/Tooltip';
import { useTokenPriceV4 } from '@app/hooks/token/useTokenPriceV4';

export const ReserveSection = () => {
  const { watch } = useFormContext();

  const listingType = watch('listingType');
  const currency = watch('currency');
  const reserve = watch('reserve');

  const reserveUsd = useTokenPriceV4(
    currency.contractAddress,
    reserve ? reserve : 0
  );

  if (listingType === BUY_IT_NOW_OPTION) {
    return null;
  }

  return (
    <Tooltip
      placement="top-start"
      triggerClassName={styles.tooltipTrigger}
      tooltip={RESERVE_TOOLTIP_MESSAGE}
    >
      <FormControl name="reserve">
        <NftPriceInput
          title="Reserve"
          tokenSymbol={currency.symbol}
          priceUsd={reserveUsd ?? 0}
          inputName="reserve"
        />
      </FormControl>
    </Tooltip>
  );
};
