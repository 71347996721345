import React from 'react';
import styles from '@app/screens/nft-marketplace/SellNftForm/SellNftForm.module.scss';
import { FormControl } from '@app/components/FormControl';
import { Controller, useFormContext } from 'react-hook-form';
import { AuctionPeriodPicker } from '@app/screens/nft-marketplace/SellNftForm/AuctionPeridodPicker';
import { Tooltip } from '@app/components/Tooltip';
import {
  BUY_IT_NOW_OPTION,
  SELECT_AUCTION_PERIOD_TOOLTIP_MESSAGE
} from '@app/screens/nft-marketplace/SellNftForm';

export const PeriodSection = () => {
  const { control, watch } = useFormContext();

  const listingType = watch('listingType');

  return (
    <Tooltip
      placement="top-start"
      triggerClassName={styles.tooltipTrigger}
      tooltip={SELECT_AUCTION_PERIOD_TOOLTIP_MESSAGE}
    >
      <FormControl name="dateRange">
        <Controller
          control={control}
          name="dateRange"
          render={({ field }) => (
            <AuctionPeriodPicker
              title={
                listingType !== BUY_IT_NOW_OPTION
                  ? 'Select Auction period'
                  : 'Select Auction period (optional)'
              }
              onChange={field.onChange}
              selectedDates={field.value ?? []}
            />
          )}
        />
      </FormControl>
    </Tooltip>
  );
};
