import { Box, Center, Grid, GridItem, Spinner, Text } from '@chakra-ui/react';
import React from 'react';
import { MadeOfferRow } from '@app/screens/nft-marketplace/SpNftOffersModal/MadeOfferRow';

import styles from '../MadeOfferRow/MadeOfferRow.module.scss';
import { useGetNftMarketplaceOffersMade } from '@app/hooks/nft/useNftMarketplaceOffersMade';
import { useMedia } from 'react-use';

export const OffersMadeList = () => {
  const { data, isLoading } = useGetNftMarketplaceOffersMade();
  const isMobile = useMedia('(max-width: 960px)', false);

  function renderContent() {
    if (isLoading) {
      return (
        <Center height="100%" flex={1}>
          <Spinner color="orange" width={12} height={12} />
        </Center>
      );
    }

    if (!data || !data.length) {
      return (
        <Center height="100%" flex={1}>
          <Text>No offers were made yet</Text>
        </Center>
      );
    }

    function renderHeader() {
      if (!isMobile) {
        return (
          <Grid className={styles.tableHeader}>
            <GridItem>
              <Text fontSize="12px">Pool</Text>
            </GridItem>
            <GridItem display="flex" justifyContent="flex-end">
              <Text fontSize="12px">Vault</Text>
            </GridItem>
            <GridItem>
              <Text fontSize="12px">Properties</Text>
            </GridItem>
            <GridItem>
              <Text fontSize="12px">Auction Type</Text>
            </GridItem>
            <GridItem>
              <Text fontSize="12px" color="primaryWhite">
                Amount{' '}
              </Text>
            </GridItem>
            <GridItem>&nbsp;</GridItem>
          </Grid>
        );
      }

      return null;
    }

    return (
      <>
        {renderHeader()}
        {data.map(item => {
          return <MadeOfferRow isMobile={isMobile} bid={item} key={item.id} />;
        })}
      </>
    );
  }

  return (
    <Box
      p={3}
      className={styles.scrollbar}
      bg="neutral.1000"
      overflowY="auto"
      maxHeight="100vh"
      borderRadius={8}
      flex={1}
    >
      {renderContent()}
    </Box>
  );
};
