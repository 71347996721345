import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm
} from 'react-hook-form';
import { Token } from '@app/types/token';
import { VStack, HStack, Text } from '@chakra-ui/react';
import React, { FC, useContext, useState } from 'react';
import { NftPriceInput } from '@app/screens/nft-marketplace/SellNftForm/NftPriceInput';
import { NftFormAssetSection } from '@app/screens/nft-marketplace/SellNftForm/NftFormAssetSection';
import { Discount } from '@app/screens/nft-marketplace/SellNftForm/Discount';
import { AuctionPeriodPicker } from '@app/screens/nft-marketplace/SellNftForm/AuctionPeridodPicker';
import { useConfig } from '@app/config';
import { TokenSelector } from '@app/components/Swap/components/TokenSelector';
import { useTokenPriceV4 } from '@app/hooks/token/useTokenPriceV4';
import { DropdownMenu } from '@app/components/DropdownMenu';
import styles from './SellNftForm.module.scss';
import { InfoButton } from '@app/screens/nft-marketplace/SellNftForm/InfoButton';
import { AuctionTypeDescription } from '@app/screens/nft-marketplace/SellNftForm/AuctionTypeDescription';
import { useToggle } from 'react-use';
import { NftAttentionModal } from '@app/screens/nft-marketplace/SellNftForm/NftAttentionModal';
import {
  PositionItemContext,
  PositionItem
} from '@app/screens/nft-marketplace/SpNftOffersModal/PositionContext/PositionItemContext';
import { Tooltip } from '@app/components/Tooltip';
import { useListNFT } from '@app/hooks/nft/useListNft';
import { parseUnits } from '@app/helpers/format';
import { SellNftSubmitButton } from '@app/screens/nft-marketplace/SellNftForm/SellNftSubmitButton/SellNftSubmitButton';
import { TransactionResultModal } from '@app/components/TransactionResultModal';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';
import { FormControl } from '@app/components/FormControl';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  SellNftFormFields,
  sellNftFormSchema
} from '@app/screens/nft-marketplace/SellNftForm/helpers';
import { useSigner } from '@thirdweb-dev/react';
import { useNftApprovePosition } from '@app/hooks/nft/useNftApprovePosition';
import { useIsNftApprovedPosition } from '@app/hooks/nft/useIsNftApprovedPosition';
import { showErrorToast, showSuccessToast } from '@app/components/Toast';

type Props = {
  onParentModalClose: () => void;
  isMobile?: boolean;
};

export const BUY_IT_NOW_OPTION = '1';
export const BUY_IT_NOW_WITH_BIDS_OPTION = '2';
export const ENGLISH_AUCTION_OPTION = '3';
export const DUTCH_AUCTION_OPTION = '4';
export const BLIND_AUCTION_OPTION = '5';

export type AuctionOption = {
  title: string;
  value: string;
};

export const auctionOptions: AuctionOption[] = [
  {
    title: 'Buy it now',
    value: BUY_IT_NOW_OPTION
  },
  {
    title: 'Buy it now with bids',
    value: BUY_IT_NOW_WITH_BIDS_OPTION
  },
  {
    title: 'English Auction',
    value: ENGLISH_AUCTION_OPTION
  }
  // {
  //   title: 'Dutch Auction',
  //   value: DUTCH_AUCTION_OPTION
  // }
  // {
  //   title: 'Blind Auction',
  //   value: BLIND_AUCTION_OPTION
  // }
];

export const SELL_NOW_PRICE_TOOLTIP_MESSAGE =
  'The price you are willing to sell without accepting bids';
export const RESERVE_TOOLTIP_MESSAGE =
  'The minimum amount that a seller is willing to accept as the winning bid.';
export const DISCOUNT_TOOLTIP_MESSAGE =
  'The difference between the deposit amount and sell price.';
export const SELECT_AUCTION_PERIOD_TOOLTIP_MESSAGE =
  'Refers to the duration of time during which bidding takes place for a specific lot or item.';
export const PRICE_OPTIONAL_TOOLTIP_MESSAGE =
  'The minimum amount above the current bid that the auctioneer asks other bidders to bid.';

export const SellNftForm: FC<Props> = ({
  onParentModalClose,
  isMobile = false
}) => {
  const position = useContext(PositionItemContext) as PositionItem;
  const appConfig = useConfig();
  const getDefaultFormValues = () => ({
    price: undefined,
    reserve: undefined,
    currency: appConfig?.CONTENT.tokensWhitelist[3],
    listingType: auctionOptions[0].value,
    dateRange: []
  });
  const methods = useForm<SellNftFormFields>({
    defaultValues: getDefaultFormValues(),
    resolver: yupResolver<SellNftFormFields>(sellNftFormSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange'
  });
  const {
    handleSubmit,
    watch,
    control,
    formState,
    getValues,
    clearErrors,
    reset
  } = methods;
  const queryClient = useQueryClient();
  const { loading, error, listNft } = useListNFT();
  const [isAttentionModalOpen, toggleAttentionModalOpen] = useToggle(false);
  const [showAuctionInfo, setShowAuctionInfo] = useToggle(false);
  const config = useConfig();
  const signer = useSigner();
  const currency = watch('currency');
  const price = watch('price');
  const reserve = watch('reserve');
  const listingType = watch('listingType');
  const sellPriceUsd = useTokenPriceV4(
    currency.contractAddress,
    price ? price : 0
  );
  const reserveUsd = useTokenPriceV4(
    currency.contractAddress,
    reserve ? reserve : 0
  );
  const [listingResult, setListingResult] = useState<{
    status: 'success' | 'error';
    title: string;
    message: string;
    txHash?: string;
  } | null>(null);
  const {
    mutateAsync: setApprovePosition,
    isLoading: isSetApproveLoading,
    error: positionApproveError
  } = useNftApprovePosition();
  const {
    data: isPositionApproved,
    isLoading: isPositionApprovalLoading,
    error: positionStatusError
  } = useIsNftApprovedPosition(
    position.poolAddress,
    position.tokenId,
    config?.CONTRACTS.NFT_MARKETPLACE as string
  );
  const filterByContractAddress = (token: Token) =>
    appConfig?.CONTENT.nftMarketplaceAllowedTokens.some(
      (allowedToken: Token) =>
        allowedToken.contractAddress.toLowerCase() ===
        token.contractAddress.toLowerCase()
    );

  const renderReserveInput = () => {
    if (listingType !== BUY_IT_NOW_OPTION) {
      return (
        <Tooltip
          placement="top-start"
          triggerClassName={styles.tooltipTrigger}
          tooltip={RESERVE_TOOLTIP_MESSAGE}
        >
          <FormControl name="reserve">
            <NftPriceInput
              title="Reserve"
              tokenSymbol={currency.symbol}
              priceUsd={reserveUsd ?? 0}
              inputName="reserve"
            />
          </FormControl>
        </Tooltip>
      );
    }

    return null;
  };

  const renderButItNowDescription = () => {
    if (listingType === BUY_IT_NOW_OPTION) {
      return (
        <Text
          color="neutral.300"
          fontWeight={400}
          fontSize="14px"
          lineHeight="18px"
        >
          One single price for a buyer
        </Text>
      );
    }

    return null;
  };

  const renderInfoButton = () => {
    if (listingType !== BUY_IT_NOW_OPTION) {
      return <InfoButton onClick={setShowAuctionInfo} />;
    }

    return null;
  };

  const renderAuctionCancellationWarning = () => {
    if (
      listingType !== BUY_IT_NOW_OPTION &&
      listingType !== BUY_IT_NOW_WITH_BIDS_OPTION
    ) {
      return (
        <Text
          color="orange"
          fontWeight="500"
          fontSize="16px"
          lineHeight="18px"
          alignSelf="center"
        >
          Auction cannot be cancelled between the start and end timestamps
        </Text>
      );
    }

    return null;
  };

  const handleApprovePositionSubmit = async () => {
    if (signer) {
      try {
        const result = await setApprovePosition({
          signer,
          contractAddress: position.poolAddress,
          tokenId: position.tokenId,
          nftMarketplaceContractAddress: config?.CONTRACTS
            .NFT_MARKETPLACE as string
        });

        if (result?.receipt.transactionHash) {
          showSuccessToast('The position is approved');
        }
      } catch (e) {
        showErrorToast("Can't approve the position");
      }
    }
  };

  const submitForm: SubmitHandler<SellNftFormFields> = async data => {
    if (!isPositionApproved) {
      await handleApprovePositionSubmit();
    } else {
      const { price, reserve, currency, dateRange, listingType } = data;
      const [start, end] = dateRange;
      let startUnix = 0;
      let endUnix = 0;

      if (start && end) {
        startUnix = Math.round(+start / 1000);
        endUnix = Math.round(+end / 1000);
      }

      try {
        const result = await listNft({
          price: price
            ? parseUnits(price.toString(), currency.decimals).toString()
            : '0',
          reserve: reserve
            ? parseUnits(reserve.toString(), currency.decimals).toString()
            : '0',
          currency: currency.contractAddress,
          start: startUnix,
          end: endUnix,
          tokenId: position.tokenId,
          nft: position.poolAddress,
          listingType: Number(listingType),
          isSemiFungible: false,
          amount: 1
        });

        if (!result.receipt && result._raw.errorName) {
          throw new Error(result._raw.errorName);
        }

        setListingResult({
          status: 'success',
          txHash: result.receipt.transactionHash,
          title: 'Success',
          message: 'The NFT is successfully listed'
        });
      } catch (e: any) {
        console.error(e.message);
        setListingResult({
          status: 'error',
          txHash: '',
          title: 'Error',
          message: 'Transaction failed.'
        });
      }
    }
  };

  const onSubmitClick: SubmitHandler<SellNftFormFields> = data => {
    if (
      listingType === ENGLISH_AUCTION_OPTION ||
      listingType === DUTCH_AUCTION_OPTION
    ) {
      toggleAttentionModalOpen(true);
    } else {
      submitForm(data);
    }
  };

  const handleModalConfirm = () => {
    const formValues = getValues();

    submitForm(formValues);
    toggleAttentionModalOpen(false);
  };

  return (
    <FormProvider {...methods}>
      <VStack
        paddingTop={isMobile ? '0' : '32px'}
        paddingBottom={isMobile ? '0' : '32px'}
        gap={isMobile ? '2px' : '16px'}
        as="form"
        alignItems="flex-start"
        onSubmit={handleSubmit(onSubmitClick)}
        noValidate
      >
        <NftFormAssetSection isMobile={isMobile} position={position} />
        <VStack width="100%" alignItems="flex-start">
          <HStack alignItems="center">
            <Text
              fontWeight="400"
              fontSize="16px"
              color="neutral.300"
              lineHeight="16px"
            >
              Select Auction type
            </Text>
            {renderInfoButton()}
          </HStack>
          <Controller
            name="listingType"
            control={control}
            render={({ field: { onChange, value } }) => (
              <DropdownMenu
                items={auctionOptions}
                onSelect={selectedValue => {
                  reset({
                    ...getDefaultFormValues(),
                    listingType: selectedValue
                  });
                }}
                selected={value}
                classes={{
                  componentContainer: styles.componentContainer,
                  headingContainer: styles.headingContainer,
                  heading: styles.auctionTypeDropdownHeading,
                  dropdownContainer: styles.auctionTypeDropdownContainer
                }}
              />
            )}
          />
          {renderButItNowDescription()}
          <AuctionTypeDescription
            isOpen={showAuctionInfo}
            selectedOptionId={listingType}
          />
        </VStack>
        <FormControl name="currency">
          <Controller
            name="currency"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TokenSelector
                selected={value}
                onSelect={onChange}
                filterByPools={true}
                disabled={false}
                showUnverifiedCheckbox={false}
                showPopularBases={false}
                filterFn={filterByContractAddress}
              />
            )}
          />
        </FormControl>
        {listingType !== ENGLISH_AUCTION_OPTION && (
          <Tooltip
            placement="top-start"
            triggerClassName={styles.tooltipTrigger}
            tooltip={
              listingType === ENGLISH_AUCTION_OPTION
                ? PRICE_OPTIONAL_TOOLTIP_MESSAGE
                : SELL_NOW_PRICE_TOOLTIP_MESSAGE
            }
          >
            <FormControl name="price">
              <NftPriceInput
                title={
                  listingType === ENGLISH_AUCTION_OPTION ||
                  listingType === BLIND_AUCTION_OPTION
                    ? 'Price (optional)'
                    : 'Sell now price'
                }
                tokenSymbol={currency.symbol}
                priceUsd={sellPriceUsd ?? 0}
                inputName="price"
              />
            </FormControl>
          </Tooltip>
        )}
        {renderReserveInput()}
        <Tooltip
          placement="top-start"
          triggerClassName={styles.tooltipTrigger}
          tooltip={DISCOUNT_TOOLTIP_MESSAGE}
        >
          <Discount
            depositedAmountUsd={position.usdAmount ?? 0}
            newPrice={
              listingType === ENGLISH_AUCTION_OPTION
                ? reserveUsd ?? 0
                : sellPriceUsd ?? 0
            }
          />
        </Tooltip>
        <Tooltip
          placement="top-start"
          triggerClassName={styles.tooltipTrigger}
          tooltip={SELECT_AUCTION_PERIOD_TOOLTIP_MESSAGE}
        >
          <FormControl name="dateRange">
            <Controller
              control={control}
              name="dateRange"
              render={({ field }) => (
                <AuctionPeriodPicker
                  title={
                    listingType !== BUY_IT_NOW_OPTION
                      ? 'Select Auction period'
                      : 'Select Auction period (optional)'
                  }
                  onChange={field.onChange}
                  selectedDates={field.value ?? []}
                />
              )}
            />
          </FormControl>
        </Tooltip>
        <SellNftSubmitButton
          isListingLoading={loading}
          isPositionApprovalLoading={isPositionApprovalLoading}
          isPositionApproved={isPositionApproved}
          isSetApproveLoading={isSetApproveLoading}
        />
        {renderAuctionCancellationWarning()}
        <NftAttentionModal
          isOpen={isAttentionModalOpen}
          onClose={toggleAttentionModalOpen}
          onConfirmClick={handleModalConfirm}
        />
        <TransactionResultModal
          isOpen={!!listingResult}
          status={listingResult?.status}
          title={listingResult?.title}
          message={listingResult?.message}
          txHash={listingResult?.txHash}
          onClose={async () => {
            setListingResult(null);
            await queryClient.invalidateQueries({
              queryKey: [QueryKeys.GET_NFT_POOLS_LIST]
            });
            await queryClient.invalidateQueries({
              queryKey: [QueryKeys.POOL_TOKEN_IDS]
            });
            onParentModalClose();
          }}
        />
      </VStack>
    </FormProvider>
  );
};
