import { useContract, useNFT } from '@thirdweb-dev/react';
import { useFindTokenInWhitelist } from '@app/hooks/nft/useFindTokenInWhitelist';
import { useGetDateRangeNftTransaction } from '@app/hooks/nft/useGetDateRangeNftTransaction';
import {
  AspectRatio,
  Box,
  Grid,
  GridItem,
  Text,
  VStack
} from '@chakra-ui/react';
import { getTypeAcronym } from '@app/screens/nft-marketplace/SpNftMarketPlaceList/SprNftMarketplaceCell/helpers';
import { SellPrice } from '@app/screens/nft-marketplace/SellPrice';
import React, { FC } from 'react';
import { NFTMarketplaceListing } from '@app/types/nftMarketplace';
import { AnimatePresence } from 'framer-motion';
import { TransactionNftDetails } from '@app/components/TransactionNftDetails';
import { useToggle } from 'react-use';
import { format } from 'date-fns';
import { ViewFilter } from '@app/hooks/history/useTransactionHistoryNFT';
import { TransactionNftHistoryTimestamp } from '@app/components/TransactionNftHistoryTimestamp';

type Props = {
  listing: NFTMarketplaceListing;
  selectedView: ViewFilter;
};

export const TransactionRewardNftHistoryItem: FC<Props> = ({
  listing,
  selectedView
}) => {
  const { tokenId, purchaseTxHash, purchaseTimestamp } = listing;
  const { contract } = useContract(listing.nftContractAddress);
  const { data: nft, isLoading } = useNFT(contract, tokenId);
  const [openDetails, toggleOpen] = useToggle(false);
  const token = useFindTokenInWhitelist(listing);

  if (!nft) {
    return null;
  }

  return (
    <Grid
      width="100%"
      borderRadius="8px"
      backgroundColor="gray.10"
      justifyItems="center"
      padding="8px 8px"
      templateColumns=".2fr .3fr .2fr .45fr .5fr"
      alignItems="center"
      cursor="pointer"
      gap={1}
    >
      <GridItem>
        <AspectRatio
          ratio={1}
          boxSize="50px"
          pos="relative"
          overflow="hidden"
          maxHeight={50}
          minWidth={50}
          borderRadius="8px"
        >
          {nft && nft.metadata.image && nft.metadata.name ? (
            <img
              src={nft.metadata.image}
              alt={nft.metadata.name.toString() ?? ''}
              style={{ objectFit: 'cover', width: '100%', height: '100%' }}
            />
          ) : (
            <Box
              height="30px"
              width="30px"
              backgroundColor="orange"
              borderRadius="19px"
            />
          )}
        </AspectRatio>
      </GridItem>
      <GridItem>
        <VStack alignItems="flex-start" gap={1}>
          <Text fontSize="12px" lineHeight="12px" color="white">
            {nft?.metadata.name}
          </Text>
          <Text fontSize="12px" color="gray.4">
            ID: {tokenId}
          </Text>
        </VStack>
      </GridItem>
      <GridItem>
        <Text fontSize="12px" color="gray.4">
          {getTypeAcronym(listing.type)}
        </Text>
      </GridItem>
      <GridItem width="100%" alignItems="start">
        {token && <SellPrice listing={listing} currency={token} />}
      </GridItem>
      <GridItem width="100%">
        <TransactionNftHistoryTimestamp
          purchaseTimestamp={purchaseTimestamp}
          selectedView={selectedView}
        />
      </GridItem>
      <AnimatePresence>
        {openDetails ? (
          <TransactionNftDetails purchaseTxHash={purchaseTxHash} />
        ) : null}
      </AnimatePresence>
    </Grid>
  );
};
