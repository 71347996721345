import { Signer } from 'ethers';
import { ThirdwebSDK } from '@thirdweb-dev/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';

async function mutation({
  contractAddress,
  tokenId,
  signer,
  nftMarketplaceContractAddress
}: {
  contractAddress: string | undefined;
  tokenId: string;
  signer: Signer | undefined;
  nftMarketplaceContractAddress: string;
}) {
  if (!contractAddress || !tokenId || !signer) {
    return null;
  }

  const sdk = ThirdwebSDK.fromSigner(signer);
  const contract = await sdk.getContract(contractAddress);

  return await contract.call('setApprovalForAll', [
    nftMarketplaceContractAddress,
    true
  ]);
}

export function useApproveNFTSpend() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: mutation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_IS_NFT_APPROVED_FOR_LISTING]
      });
    },
    onError: (error, variables, context) => {
      console.error('Error on position approve:', error);
    }
  });
}
