import React, { FC } from 'react';
import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react';
import { SellNftForm } from '@app/screens/nft-marketplace/SellNftForm';
import { ListNftForm } from '@app/screens/nft-marketplace/SpNftOffersModal/RewardNftsList/components/ListNFTForm';
import { NFT } from '@thirdweb-dev/sdk';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  data: NFT;
  contract: string;
}

export const ListNftModal: FC<Props> = ({
  isOpen,
  onClose,
  data,
  contract
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset="slideInBottom"
      size="sm"
    >
      <ModalOverlay />
      <ModalContent sx={{ maxWidth: ['100vw', '640px'], padding: '24px' }}>
        <HStack justifyContent="space-between">
          <ModalHeader whiteSpace="nowrap">List NFT</ModalHeader>
          <ModalCloseButton />
        </HStack>
        <ModalBody>
          <ListNftForm onClose={onClose} data={data} contract={contract} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
