import { NFTMarketplaceListing } from '@app/types/nftMarketplace';
import React, { FC, useMemo } from 'react';
import { useFindTokenInWhitelist } from '@app/hooks/nft/useFindTokenInWhitelist';
import { format } from 'date-fns';
import {
  AspectRatio,
  Box,
  Grid,
  GridItem,
  HStack,
  Link,
  Text,
  VStack
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { APP_ROUTES } from '@app/constants/routes';
import { getTypeAcronym } from '@app/screens/nft-marketplace/SpNftMarketPlaceList/SprNftMarketplaceCell/helpers';
import { SellPrice } from '@app/screens/nft-marketplace/SellPrice';
import { useContract, useNFT } from '@thirdweb-dev/react';
import { useGetDateRangeNftTransaction } from '@app/hooks/nft/useGetDateRangeNftTransaction';

type Props = {
  listing: NFTMarketplaceListing;
};

export const TransactionActiveRewardNftHistoryItem: FC<Props> = ({
  listing
}) => {
  const { id, tokenId, token0, token1 } = listing;
  const { contract } = useContract(listing.nftContractAddress);
  const { data: nft, isLoading } = useNFT(contract, tokenId);

  const token = useFindTokenInWhitelist(listing);

  const dateRange = useGetDateRangeNftTransaction(listing);

  if (!nft) {
    return null;
  }

  const renderDate = (label: string, date: string) => {
    return (
      <HStack alignItems="flex-start" key={label + date} width="100%">
        <Text fontSize="12px" lineHeight="12px">
          {label}:
        </Text>
        <Text color="gray.4" fontSize="12px" lineHeight="12px">
          {date}
        </Text>
      </HStack>
    );
  };

  return (
    <Link as={NextLink} href={APP_ROUTES.nftRewardDetailsPage(id)} width="100%">
      <Grid
        width="100%"
        borderRadius="8px"
        backgroundColor="gray.10"
        justifyItems="center"
        padding="8px 8px"
        templateColumns=".2fr .3fr .2fr .45fr .5fr"
        alignItems="center"
        cursor="pointer"
        gap={1}
      >
        <GridItem>
          <AspectRatio
            ratio={1}
            boxSize="50px"
            pos="relative"
            overflow="hidden"
            maxHeight={50}
            minWidth={50}
            borderRadius="8px"
          >
            {nft && nft.metadata.image && nft.metadata.name ? (
              <img
                src={nft.metadata.image}
                alt={nft.metadata.name.toString() ?? ''}
                style={{ objectFit: 'cover', width: '100%', height: '100%' }}
              />
            ) : (
              <Box
                height="30px"
                width="30px"
                backgroundColor="orange"
                borderRadius="19px"
              />
            )}
          </AspectRatio>
        </GridItem>
        <GridItem>
          <VStack alignItems="flex-start" gap={1}>
            <Text fontSize="12px" lineHeight="12px" color="white">
              {nft?.metadata.name}
            </Text>
            <Text fontSize="12px" color="gray.4">
              ID: {tokenId}
            </Text>
          </VStack>
        </GridItem>
        <GridItem>
          <Text fontSize="12px" color="gray.4">
            {getTypeAcronym(listing.type)}
          </Text>
        </GridItem>
        <GridItem width="100%" alignItems="start">
          {token && <SellPrice listing={listing} currency={token} />}
        </GridItem>
        <GridItem width="100%">
          <VStack width="100%" alignItems="flex-start">
            {dateRange ? (
              <>
                {renderDate('Start', dateRange[0])}
                {renderDate('End', dateRange[1])}
              </>
            ) : (
              <>
                {renderDate('Start', '-')}
                {renderDate('End', '-')}
              </>
            )}
          </VStack>
        </GridItem>
      </Grid>
    </Link>
  );
};
