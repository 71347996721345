import React, { FC } from 'react';
import {
  AspectRatio,
  Box,
  Grid,
  GridItem,
  HStack,
  Text,
  VStack
} from '@chakra-ui/react';
import { NFT } from '@thirdweb-dev/sdk';
import { ListNftButton } from '@app/screens/nft-marketplace/ListNftButton';
import { ListNftModal } from '@app/screens/nft-marketplace/SpNftOffersModal/RewardNftsList/components/ListNFTModal';
import { useToggle } from 'react-use';

type Props = {
  nft: NFT;
  contract: string;
};

export const RewardNftMenuItem: FC<Props> = ({ nft, contract }) => {
  const [open, toggleOpen] = useToggle(false);

  if (!nft) {
    return null;
  }

  const renderRewardTrait = () => {
    if (nft?.metadata && Array.isArray(nft?.metadata.attributes)) {
      const trait = nft?.metadata.attributes[0] as {
        trait_type: string;
        value: string;
      };

      return (
        <HStack justifyContent="space-between" padding={'2px 8px'}>
          <Text
            fontSize={'14px'}
            lineHeight="26px"
            fontWeight="400"
            color="meme"
          >
            {trait.trait_type} - {trait.value}
          </Text>
        </HStack>
      );
    }

    return null;
  };

  return (
    <>
      <Grid
        width="100%"
        borderRadius="8px"
        backgroundColor="back"
        border="1px solid"
        borderColor="whiteOpacity.6"
        justifyItems="center"
        padding="8px 8px"
        templateColumns="1fr 1fr 1fr 1fr"
        alignItems="center"
        cursor="pointer"
        gap={1}
      >
        <GridItem>
          <AspectRatio
            ratio={1}
            boxSize="50px"
            pos="relative"
            overflow="hidden"
            maxHeight={50}
            minWidth={50}
            borderRadius="8px"
          >
            {nft && nft.metadata.image && nft.metadata.name ? (
              <img
                src={nft.metadata.image}
                alt={nft.metadata.name.toString() ?? ''}
                style={{ objectFit: 'cover', width: '100%', height: '100%' }}
              />
            ) : (
              <Box
                height="30px"
                width="30px"
                backgroundColor="orange"
                borderRadius="19px"
              />
            )}
          </AspectRatio>
        </GridItem>
        <GridItem>
          <VStack alignItems="flex-start" gap={1}>
            <Text fontSize="12px" lineHeight="12px" color="white">
              {nft?.metadata.name}
            </Text>
            <Text fontSize="12px" color="gray.4">
              ID: {nft.metadata.id}
            </Text>
          </VStack>
        </GridItem>
        <GridItem>{renderRewardTrait()}</GridItem>
        <GridItem>
          <ListNftButton onClick={toggleOpen} />
        </GridItem>
      </Grid>
      <ListNftModal
        isOpen={open}
        onClose={toggleOpen}
        data={nft}
        contract={contract}
      />
    </>
  );
};
