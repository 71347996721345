import React, { FC, useMemo } from 'react';
import { Button, Grid, GridItem, HStack, Text, VStack } from '@chakra-ui/react';
import { LogoSection } from '@app/screens/positions/components/SpNftPositionListRowItem/components/LogoSection';
import { SymbolsSection } from '@app/screens/positions/components/SpNftPositionListRowItem/components/SymbolsSection';
import { useKpNftPositionDetails } from '@app/screens/positions/components/SpNftPositionListRowItem/hooks';
import { NFTMarketplaceBid } from '@app/types/nftMarketplace';
import { StrategySection } from '@app/components/StrategySection';
import { PropertiesSection } from '@app/screens/positions/components/SpNftPositionListRowItem/components/PropertiesSection';
import { useKpNftLockDetails } from '@app/hooks/plugins/useKpNftLockDetails';
import { getTypeAcronym } from '@app/screens/nft-marketplace/SpNftMarketPlaceList/SprNftMarketplaceCell/helpers';
import { useConfig } from '@app/config';
import { getTokensWhitelist } from '@app/constants/tokensWhitelist';
import { formatUnits } from 'ethers/lib/utils';

import styles from './MadeOfferRow.module.scss';
import { useCancelBid } from '@app/hooks/nft/useCancelBid';
import { useSigner } from '@thirdweb-dev/react';
import { PremiumNftBadge } from '@app/screens/nft-marketplace/PremiumNftBadge';
import { AmountSection } from '@app/screens/positions/components/SpNftPositionListRowItem/components/AmountSection';
import { ListNftButton } from '@app/screens/nft-marketplace/ListNftButton';

interface Props {
  bid: NFTMarketplaceBid;
  isMobile?: boolean;
}

export const MadeOfferRow: FC<Props> = ({ bid, isMobile = false }) => {
  const { tokenId, nftPool } = bid.listing;
  const signer = useSigner();
  const { mutate: cancelBid, isLoading: isCancelling } = useCancelBid();

  const { vault, data, usdAmount } = useKpNftPositionDetails(
    tokenId,
    nftPool.id,
    nftPool.lpToken?.id ?? ''
  );

  const lockDetails = useKpNftLockDetails(data);

  const config = useConfig();
  const TOKENS_WHITELIST = getTokensWhitelist(config);

  const sellCurrency = useMemo(() => {
    return TOKENS_WHITELIST.find(
      item =>
        item.contractAddress.toLowerCase() ===
        bid.listing.currency.toLowerCase()
    );
  }, [bid.listing, TOKENS_WHITELIST]);

  function isCancelable() {
    if (bid.listing.type === 'BUY_IT_NOW_WITH_BIDS') {
      return true;
    }

    if (bid.listing.type === 'ENGLISH_AUCTION') {
      const now = Date.now();

      return now >= +bid.listing.start * 1000 && now < +bid.listing.end * 1000;
    }

    return false;
  }

  return (
    <>
      {isMobile ? (
        <VStack
          gap={3}
          width="100%"
          border="1px solid"
          borderColor="gray.5"
          p="10px 14px"
          align="center"
          spacing="16px"
          mb="14px"
          transition="background-color 0.2s ease"
          borderRadius="16px"
          marginTop="16px"
        >
          <HStack>
            <LogoSection vault={vault} tokenDetails={nftPool.lpToken} />
            <VStack alignItems="flex-start" gap={1}>
              <SymbolsSection
                fontSize="14px"
                vault={vault}
                tokenDetails={nftPool.lpToken}
              />
              <Text fontSize="14px" color="gray.4">
                ID: {tokenId}
              </Text>
            </VStack>
          </HStack>
          <HStack
            alignItems="flex-start"
            justifyContent="space-between"
            width="100%"
          >
            <Text fontSize="14px" color="gray.4">
              Strategy
            </Text>
            <StrategySection vault={vault} />
          </HStack>
          <HStack width="100%" justifyContent="space-between">
            <Text fontSize="14px" lineHeight="26px" color="gray.4">
              Properties
            </Text>
            <PropertiesSection
              lockDetails={lockDetails}
              poolAddress={nftPool.id}
              tokenId={tokenId}
            />
          </HStack>
          <HStack width="100%" justifyContent="space-between">
            <Text fontSize="14px" lineHeight="26px" color="gray.4">
              Auction Type
            </Text>
            <Text fontSize="14px" lineHeight="26px" color="gray.4">
              {getTypeAcronym(bid.listing.type)}
            </Text>
          </HStack>
          <HStack width="100%" justifyContent="space-between">
            <Text fontSize="14px" lineHeight="26px" color="gray.4">
              Amount
            </Text>
            <Text fontSize="14px" lineHeight="26px" color="gray.4">
              {formatUnits(bid.amount, sellCurrency?.decimals)}{' '}
              {sellCurrency?.symbol}
            </Text>
          </HStack>
          <HStack width="100%">
            {isCancelable() && (
              <Button
                fontSize="14px"
                width="100%"
                isDisabled={isCancelling}
                variant="rowCompact"
                onClick={() => {
                  cancelBid({
                    listingId: bid.listing.id,
                    signer
                  });
                }}
              >
                {isCancelling ? 'Processing' : 'Cancel'}
              </Button>
            )}
          </HStack>
        </VStack>
      ) : (
        <Grid className={styles.tableRow}>
          <GridItem display="flex" alignItems="center">
            <HStack>
              <LogoSection vault={vault} tokenDetails={nftPool.lpToken} />
              <VStack alignItems="flex-start" gap={1}>
                <SymbolsSection
                  fontSize="12px"
                  vault={vault}
                  tokenDetails={nftPool.lpToken}
                />
                <Text fontSize="12px" color="gray.4">
                  ID: {tokenId}
                </Text>
              </VStack>
            </HStack>
          </GridItem>
          <GridItem display="flex" alignItems="flex-start">
            <StrategySection vault={vault} />
          </GridItem>
          <GridItem>
            <PropertiesSection
              lockDetails={lockDetails}
              poolAddress={nftPool.id}
              tokenId={tokenId}
            />
          </GridItem>
          <GridItem>
            <Text fontSize="12px" lineHeight="26px" color="gray.4">
              {getTypeAcronym(bid.listing.type)}
            </Text>
          </GridItem>
          <GridItem>
            <Text fontSize="12px" lineHeight="26px" color="gray.4">
              {formatUnits(bid.amount, sellCurrency?.decimals)}{' '}
              {sellCurrency?.symbol}
            </Text>
          </GridItem>
          <GridItem justifyContent="flex-end" display="flex">
            {isCancelable() && (
              <Button
                isDisabled={isCancelling}
                variant="rowCompact"
                onClick={() => {
                  cancelBid({
                    listingId: bid.listing.id,
                    signer
                  });
                }}
              >
                {isCancelling ? 'Processing' : 'Cancel'}
              </Button>
            )}
          </GridItem>
        </Grid>
      )}
    </>
  );
};
