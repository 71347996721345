import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';
import { Chain } from '@thirdweb-dev/chains';
import { getContract, useContract } from '@thirdweb-dev/react';
import nftMarketplaceAbi from 'abis/NFTMarketPlace.json';
import { useConfig } from '@app/config';
import { ApplicationConfig } from '@app/config/types';
import { useSupportedChain } from '@app/hooks/other/useSupportedChain';
import { useMemo } from 'react';
import { useAsync } from 'react-use';

async function fetcher(
  network: Chain | undefined,
  config: ApplicationConfig | undefined
) {
  if (!network || !config) {
    return null;
  }

  try {
    const contract = await getContract({
      address: config.CONTRACTS.NFT_MARKETPLACE,
      network,
      contractTypeOrAbi: nftMarketplaceAbi
    });

    // @ts-ignore
    const premiumNftAddress = await contract.call('premiumBuyerNFT');

    return premiumNftAddress;
  } catch (e) {
    console.error(e);

    return null;
  }
}

export function usePremiumBuyerNft() {
  const config = useConfig();
  const chain = useSupportedChain();

  const { data: premiumNftAddress } = useQuery<string | null>(
    [QueryKeys.GET_PREMIUM_BUYER_NFR, chain?.chainId, !!config],
    () => fetcher(chain, config),
    {
      enabled: Boolean(chain && config)
    }
  );

  const { contract } = useContract(premiumNftAddress);

  const { value: name } = useAsync(async () => {
    if (!contract) {
      return;
    }

    return await contract.call('name');
  }, [contract]);

  return useMemo(() => {
    return {
      name: name ?? '',
      address: premiumNftAddress
    };
  }, [name, premiumNftAddress]);
}
