import { useEffect, useRef } from 'react';

export const useNftGetUpdatedPrice = (
  usdPrice?: number | null,
  onUsdPriceReady?: (val: number) => void | null
) => {
  const usdUpdatedRef = useRef(false);

  useEffect(() => {
    if (usdPrice && !usdUpdatedRef.current && onUsdPriceReady) {
      usdUpdatedRef.current = true;
      onUsdPriceReady(usdPrice);
    }
  }, [usdPrice, onUsdPriceReady]);
};
