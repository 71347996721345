import { getContract, useAddress } from '@thirdweb-dev/react';
import nftCollection from '../../../abis/nftCollection.json';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';
import { ModeChain } from '@app/constants/chains';

async function fetcher({
  contractAddress,
  address,
  nftMarketplaceAddress
}: {
  contractAddress: string;
  address: string | undefined;
  nftMarketplaceAddress: string;
}) {
  if (!address || !nftMarketplaceAddress) {
    return null;
  }

  const contract = await getContract({
    address: contractAddress,
    network: ModeChain,
    contractTypeOrAbi: nftCollection
  });
  //@ts-ignore
  const result = await contract.call('isApprovedForAll', [
    address,
    nftMarketplaceAddress
  ]);

  return result;
}

export function useNftSpendApproval(
  contractAddress: string,
  nftMarketplaceAddress: string
) {
  const address = useAddress();

  return useQuery(
    [
      QueryKeys.GET_NFT_SPEND_APPROVAL,
      contractAddress,
      address,
      nftMarketplaceAddress
    ],
    () => fetcher({ contractAddress, address, nftMarketplaceAddress }),
    { enabled: !!nftMarketplaceAddress && !!address }
  );
}
