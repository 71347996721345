import { Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { FC, useEffect, useMemo, useRef } from 'react';
import { TokenIcon } from '@app/components/TokenIcon';
import { useGetBestBidForListing } from '@app/hooks/nft/useGetBestBidForListing';
import { NFTMarketplaceListing } from '@app/types/nftMarketplace';
import { AUCTION_TYPES } from '@app/constants/nftMarketplace';
import { formatUnits } from 'ethers/lib/utils';
import { Token } from '@app/types/token';
import { useTokenPriceV4 } from '@app/hooks/token/useTokenPriceV4';
import { useNftPriceDetails } from '@app/hooks/nft/useNftPriceDetails';
import { useNftGetUpdatedPrice } from '@app/hooks/nft/useNftGetUpdatedPrice';

type Props = {
  currency: Token;
  listing: NFTMarketplaceListing;
  onUsdPriceReady?: (val: number) => void;
};

export const SellPrice: FC<Props> = ({
  currency,
  listing,
  onUsdPriceReady
}) => {
  const { priceUSD, currentPrice, binValue } = useNftPriceDetails(
    listing,
    currency
  );

  useNftGetUpdatedPrice(priceUSD, onUsdPriceReady);

  return (
    <VStack alignItems="flex-start" gap={1}>
      <HStack alignItems="center" gap={1}>
        <TokenIcon
          width="20px"
          height="20px"
          symbol={currency.symbol}
          marginRight="4px"
        />
        <Text fontWeight={500} fontSize="14px">
          {currentPrice ? formatUnits(currentPrice, currency.decimals) : '-'}
        </Text>
      </HStack>
      {binValue && (
        <Text fontWeight={500} fontSize="12px" color="neutral.400">
          BIN: {binValue}
        </Text>
      )}
    </VStack>
  );
};
