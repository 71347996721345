import React, { FC, useMemo } from 'react';
import { useKpNftPositions } from '@app/hooks/nft/useKpNftPositions';
import { useMedia } from 'react-use';
import {
  Center,
  Grid,
  GridItem,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react';
import { NoKpNftView } from '@app/screens/positions/components/NoKpNftView';
import { MySpNftHeader } from '@app/screens/nft-marketplace/SpNftOffersModal/MySpNftTab/MySpNFTHeader';
import { AnimatePresence } from 'framer-motion';
import { StaggeredList } from '@app/components/StaggeredList';
import { SpNftModalRowItem } from '@app/screens/nft-marketplace/SpNftOffersModal/SpNftModalRow';
import { PairToken } from '@app/types/pool';
import { NftMarketplaceMenuPositionItem } from '@app/screens/nft-marketplace/NftMarketplaceMenuSection/NftMarketplaceMenuPositionItem/NftMarketplaceMenuPositionItem';

type Props = {};

export const NftMarketplaceMenuPositions: FC<Props> = ({}) => {
  const { data: kpNftPositions, isLoading } = useKpNftPositions();
  const isMobile = useMedia('(max-width: 480px)', true);
  const mergedData = useMemo(() => {
    if (!kpNftPositions) {
      return [];
    }

    return [
      ...kpNftPositions.map(pos => {
        return {
          data: pos,
          isStakedToNitro: false,
          nitroPoolId: undefined,
          poolAddress: pos.nftPool.id,
          lpToken: pos.nftPool.lpToken
        };
      })
    ];
  }, [kpNftPositions]);

  function renderContent() {
    if (isLoading) {
      return (
        <Center height="100%">
          <Spinner color="orange" width={12} height={12} />
        </Center>
      );
    }

    if (!mergedData || !mergedData.length) {
      return (
        <Center height="100%">
          <NoKpNftView />;
        </Center>
      );
    }

    function renderHeader() {
      if (!isMobile) {
        return (
          <Grid
            p="12px"
            gap={1}
            templateColumns="1.5fr repeat(4, 1fr)"
            width="100%"
          >
            <GridItem>
              <Text fontSize={14} color="neutral.300">
                Pool
              </Text>
            </GridItem>
            <GridItem justifyContent="center" display="flex">
              <Text fontSize={14} color="neutral.300">
                Vault
              </Text>
            </GridItem>
            <GridItem>
              <Text fontSize={14} color="neutral.300">
                Properties
              </Text>
            </GridItem>
            <GridItem justifyContent="flex-end" display="flex">
              <Text fontSize={14} color="neutral.300">
                Amount{' '}
              </Text>
            </GridItem>
            <GridItem colSpan={1} />
          </Grid>
        );
      }

      return null;
    }

    return (
      <>
        {renderHeader()}
        <AnimatePresence>
          <StaggeredList once={false}>
            {mergedData?.map(item => {
              return (
                <NftMarketplaceMenuPositionItem
                  isMobile={isMobile}
                  key={item.data.tokenId}
                  poolAddress={item.poolAddress}
                  tokenId={item.data.tokenId}
                  tokenDetails={item.lpToken as PairToken}
                />
              );
            })}
          </StaggeredList>
        </AnimatePresence>
      </>
    );
  }

  return (
    <VStack
      p={3}
      bg="neutral.1000"
      borderRadius={8}
      flex={1}
      width="100%"
      height="calc(100vh - 200px)"
      overflowY="auto"
      gap="8px"
    >
      {renderContent()}
    </VStack>
  );
};
