import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';
import { useConfig } from '@app/config';
import { useSupportedChain } from '@app/hooks/other/useSupportedChain';
import { gql, request } from 'graphql-request';
import { EMPTY_ADDRESS } from '@app/constants/common';

export type NFTPool = {
  id: string;
  lpToken: {
    id: string;
    symbol: string;
    decimals: string;
    name: string;
  };
};

async function fetcher(apiUrl: string | undefined) {
  if (!apiUrl) {
    return null;
  }

  const res = await request<{ nftpools: NFTPool[] }>(
    `${apiUrl}`,
    gql`
      query {
        nftpools {
          id
          lpToken {
            symbol
            id
            decimals
            name
          }
        }
      }
    `
  );

  return res.nftpools.filter(item => {
    return item.id !== EMPTY_ADDRESS && item.lpToken.symbol !== 'unknown';
  });
}

export function useGetNftPoolsList() {
  const config = useConfig();
  const chainId = useSupportedChain()?.chainId;

  return useQuery(
    [QueryKeys.GET_NFT_POOLS_LIST, chainId, config?.URLS.nftPoolsSubgraph],
    () => fetcher(config?.URLS.nftPoolsSubgraph),
    {
      enabled: !!config?.URLS.nftPoolsSubgraph && !!chainId,
      staleTime: 10000,
      refetchOnWindowFocus: false
    }
  );
}
