import { ApplicationConfig } from '@app/config/types';

export function getAllowedCurrencies(config: ApplicationConfig | undefined) {
  if (!config) {
    return [];
  }

  return config.CONTENT.tokensWhitelist.filter(
    item => item.symbol === 'USDC' || item.symbol === 'USDT'
  );
}
