import React from 'react';
import { Tooltip } from '@app/components/Tooltip';
import styles from '@app/screens/nft-marketplace/SellNftForm/SellNftForm.module.scss';
import { FormControl } from '@app/components/FormControl';
import { NftPriceInput } from '@app/screens/nft-marketplace/SellNftForm/NftPriceInput';
import {
  BLIND_AUCTION_OPTION,
  ENGLISH_AUCTION_OPTION,
  PRICE_OPTIONAL_TOOLTIP_MESSAGE,
  SELL_NOW_PRICE_TOOLTIP_MESSAGE
} from '@app/screens/nft-marketplace/SellNftForm';
import { useFormContext } from 'react-hook-form';
import { useTokenPriceV4 } from '@app/hooks/token/useTokenPriceV4';

export const PriceSection = () => {
  const { watch } = useFormContext();

  const listingType = watch('listingType');
  const currency = watch('currency');
  const price = watch('price');

  const sellPriceUsd = useTokenPriceV4(
    currency.contractAddress,
    price ? price : 0
  );

  if (listingType === ENGLISH_AUCTION_OPTION) {
    return null;
  }

  return (
    <Tooltip
      placement="top-start"
      triggerClassName={styles.tooltipTrigger}
      tooltip={
        listingType === ENGLISH_AUCTION_OPTION
          ? PRICE_OPTIONAL_TOOLTIP_MESSAGE
          : SELL_NOW_PRICE_TOOLTIP_MESSAGE
      }
    >
      <FormControl name="price">
        <NftPriceInput
          title={
            listingType === ENGLISH_AUCTION_OPTION ||
            listingType === BLIND_AUCTION_OPTION
              ? 'Price (optional)'
              : 'Sell now price'
          }
          tokenSymbol={currency.symbol}
          priceUsd={sellPriceUsd ?? 0}
          inputName="price"
        />
      </FormControl>
    </Tooltip>
  );
};
