import clsx from 'clsx';
import { Box, Heading, HStack, VStack, Text } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { useMedia } from 'react-use';
import { AnimateHeight } from '@app/components/AnimateHeight';
import { Icon } from '@app/components/Icon';
import { useOnClickOutside } from '@app/hooks/other/useOnClickOutside';

import s from './DropdownMenu.module.scss';

const DEFAULT_POPPER_SKIDDING = 0;
const DEFAULT_POPPER_DISTANCE = 8;

type MenuItem = {
  title?: string;
  icon?: ReactElement<typeof Icon> | React.JSX.Element;
  value: string;
};

interface Props {
  disabled?: boolean;
  items: MenuItem[];
  iconPosition?: 'start' | 'end' | 'only';
  title?: string;
  onSelect: (val: string) => void;
  selected: string;
  classes?: {
    componentContainer?: string;
    headingContainer?: string;
    heading?: string;
    dropdownAnimationContainer?: string;
    dropdownContainer?: string;
    dropdownLink?: string;
    dropdownContent?: string;
    right?: string;
    onActive?: {
      heading?: string;
      dropdown?: string;
    };
  };
  skidding?: number;
  distance?: number;
  showSelectedIconOnly?: boolean;
}

const variants = {
  open: {
    opacity: 1,
    height: 'auto'
  },
  collapsed: { opacity: 0, height: 0 }
};

export const DropdownMenu: FC<Props> = ({
  disabled,
  title,
  iconPosition,
  items,
  onSelect,
  selected,
  classes,
  skidding = DEFAULT_POPPER_SKIDDING,
  distance = DEFAULT_POPPER_DISTANCE,
  showSelectedIconOnly
}) => {
  const isMobile = useMedia('(max-width: 960px)', false);
  const ref = useRef<Element | null>(null);
  const [mounted, setMounted] = useState(false);

  const [open, setOpen] = useState(false);
  const [popperElement, setPopperElement] = useState<HTMLElement>();
  const [referenceElement, setReferenceElement] = useState<HTMLElement>();

  useEffect(() => {
    ref.current = document.querySelector<HTMLElement>('#portal');
    setMounted(true);
  }, []);

  useOnClickOutside(
    [
      { current: popperElement } as React.RefObject<HTMLElement>,
      { current: referenceElement } as React.RefObject<HTMLElement>
    ],
    () => setOpen(false)
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: 'fixed',
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [skidding, distance]
        }
      }
    ]
  });

  const renderItemTitle = (item: MenuItem, isSelected = false) => {
    const hasTitle = !!item?.title; // Check if title exists

    if (iconPosition === 'only' || !hasTitle) {
      return item?.icon;
    }

    return (
      <HStack>
        {iconPosition === 'start' && <span>{item.icon}</span>}
        {isSelected
          ? hasTitle && !showSelectedIconOnly && <span>{item.title}</span>
          : hasTitle && <span>{item.title}</span>}
        {iconPosition === 'end' && <span>{item.icon}</span>}
      </HStack>
    );
  };

  function renderContent() {
    return items.map((item, index) => (
      <Box
        key={item?.title ? item.title : item.value + index}
        className={clsx(s.link, classes?.dropdownLink)}
        onClick={() => {
          onSelect(item.value);
          setOpen(false);
        }}
      >
        {renderItemTitle(item)}
      </Box>
    ));
  }

  function renderMobileContent() {
    return items.map((item, index) => (
      <Box
        key={item?.title ? item.title : item.value + index}
        onClick={() => {
          onSelect(item.value);
          setOpen(false);
        }}
        className={clsx(s.link, s.mobileLink)}
      >
        {renderItemTitle(item)}
      </Box>
    ));
  }

  function getDropdown() {
    return (
      <Box
        ref={setPopperElement as React.LegacyRef<HTMLDivElement>}
        style={styles.popper}
        {...attributes.popper}
        className={clsx(
          s.dropdown,
          classes?.dropdownContainer,
          'dropdown-menu'
        )}
      >
        <motion.div
          transition={{ delay: 0, duration: 0.2 }}
          initial={{ opacity: 0, transform: 'translateY(10px)' }}
          animate={{ opacity: 1, transform: 'translateY(0px)' }}
          className={clsx(classes?.dropdownAnimationContainer)}
          exit={{
            opacity: 0
          }}
        >
          <VStack
            alignItems="flex-start"
            className={clsx(s.dropdownContent, classes?.dropdownContent)}
          >
            {renderContent()}
          </VStack>
        </motion.div>
      </Box>
    );
  }

  if (!items.length) {
    return null;
  }

  const selectedLabel = items.find(item => item.value === selected);

  return (
    <Box
      width={['100%', 'fit-content']}
      className={clsx(classes?.headingContainer)}
      gap={2}
    >
      <VStack
        alignItems="flex-start"
        position="relative"
        width={['100%', 'fit-content']}
        className={clsx(classes?.headingContainer)}
      >
        {title && (
          <Text
            fontSize={14}
            color="neutral.300"
            position="absolute"
            top="-20px"
          >
            {title}
          </Text>
        )}
        <Heading
          as="h3"
          className={clsx(s.heading, classes?.heading, {
            [classes?.onActive?.heading!]: open,
            [s.disabled]: disabled
          })}
          ref={setReferenceElement as React.LegacyRef<HTMLHeadingElement>}
          onClick={() => {
            if (disabled) {
              return;
            }

            setOpen(!open);
          }}
          width={['100%', 'fit-content']}
        >
          {renderItemTitle(selectedLabel as MenuItem, true)}
          <Icon name="chevronDown" className={s.icon} />
        </Heading>
      </VStack>
      {isMobile ? (
        <>
          <AnimateHeight
            ease="easeOut"
            variants={variants}
            duration={0.3}
            className={clsx(s.right, classes?.right, {
              [s.isOpen]: open
            })}
            isVisible={open}
          >
            {renderMobileContent()}
          </AnimateHeight>
        </>
      ) : (
        mounted &&
        ref.current &&
        ReactDOM.createPortal(
          <AnimatePresence>{open && getDropdown()}</AnimatePresence>,
          ref.current
        )
      )}
    </Box>
  );
};
