import React, { FC } from 'react';
import { Text } from '@chakra-ui/react';
import { MdOutlineWorkspacePremium } from 'react-icons/md';
import { Tooltip } from '@app/components/Tooltip';

type Props = {
  size?: number;
};

export const PremiumNftBadge: FC<Props> = ({ size = 24 }) => {
  return (
    <Tooltip tooltip={<Text color="premium">Premium</Text>}>
      <MdOutlineWorkspacePremium
        color="var(--chakra-colors-premium)"
        size={size}
      />
    </Tooltip>
  );
};
