import React, { FC, useState } from 'react';
import {
  Flex,
  HStack,
  IconButton,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from '@chakra-ui/react';
import { Icon } from '@app/components/Icon';
import { OffersMadeList } from '@app/screens/nft-marketplace/SpNftOffersModal/OffersMadeList';
import { OffersReceivedList } from '@app/screens/nft-marketplace/SpNftOffersModal/OffersReceivedList';
import { RewardsNftListMenu } from '@app/screens/nft-marketplace/NftMarketplaceMenuSection/RewardsNftListMenu';
import { NftMarketplaceMenuPositions } from '@app/screens/nft-marketplace/NftMarketplaceMenuSection/NftMarketplaceMenuPositions';
import s from './NftMarketplaceMenuSection.module.scss';

type Props = {
  onClose: (nxtVal?: boolean) => void;
};

const OFFERS_MADE_TAB = 'Offers made';
const OFFERS_RECEIVED_TAB = 'Offers received';
const MY_KP_NFT_TAB = 'My kpNFTs';
const REWARD_NFT_TAB = 'My Rewards NFTs';

export const NftMarketplaceMenuSection: FC<Props> = ({ onClose }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  return (
    <Flex
      sx={{
        background: 'gray.9',
        width: '100%',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <HStack
        justifyContent="space-between"
        alignItems="center"
        mb="24px"
        p="24px 24px 0"
      >
        <Text sx={{ fontSize: '28px', fontWeight: 500, color: 'white' }}>
          NFT Options
        </Text>
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            borderRadius: '4px',
            border: '1px solid',
            borderColor: 'whiteOpacity.6',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '11px 10px'
          }}
        >
          <Icon name="close" />
        </IconButton>
      </HStack>
      <Tabs index={tabIndex} onChange={handleTabsChange}>
        <TabList className={s.tabs}>
          <Tab className={s.tab}>{OFFERS_MADE_TAB}</Tab>
          <Tab className={s.tab}>{OFFERS_RECEIVED_TAB}</Tab>
          <Tab className={s.tab}>{MY_KP_NFT_TAB}</Tab>
          <Tab className={s.tab}>{REWARD_NFT_TAB}</Tab>
        </TabList>
        <TabIndicator mt="-3px" height="3px" bg="orange" borderRadius="1px" />
        <TabPanels>
          <TabPanel mt={6}>{tabIndex === 0 && <OffersMadeList />}</TabPanel>
          <TabPanel mt={6}>{tabIndex === 1 && <OffersReceivedList />}</TabPanel>
          <TabPanel mt={6}>
            {tabIndex === 2 && <NftMarketplaceMenuPositions />}
          </TabPanel>
          <TabPanel mt={6}>{tabIndex === 3 && <RewardsNftListMenu />}</TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};
