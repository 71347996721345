import React, { FC } from 'react';
import { useConfig } from '@app/config';
import { useOwnedRewardsNfts } from '@app/hooks/nft/useOwnedRewardsNfts';
import { Box, Center, Spinner } from '@chakra-ui/react';
import { NoKpNftView } from '@app/screens/positions/components/NoKpNftView';
import { usePremiumBuyerNft } from '@app/hooks/nft/usePremiumBuyerNft';
import { RewardNftMenuItem } from '@app/screens/nft-marketplace/NftMarketplaceMenuSection/RewardNftMenuItem';

type Props = {};

export const RewardsNftListMenu: FC<Props> = ({}) => {
  const { address: premiumBuyerNftAddress } = usePremiumBuyerNft();
  const config = useConfig();
  const { data, isLoading, name } = useOwnedRewardsNfts(premiumBuyerNftAddress);

  function renderContent() {
    if (
      !premiumBuyerNftAddress ||
      !config?.NFT_MARKETPLACE.rewardNftsWhitelist
    ) {
      return null;
    }

    if (isLoading) {
      return (
        <Center height="100%">
          <Spinner color="orange" width={12} height={12} />
        </Center>
      );
    }

    if (!data || !data.length) {
      return (
        <Center height="100%">
          <NoKpNftView />;
        </Center>
      );
    }

    return data.map(nft => {
      return (
        <RewardNftMenuItem
          key={nft.metadata.id}
          nft={nft}
          contract={premiumBuyerNftAddress}
        />
      );
    });
  }

  return (
    <Box
      sx={{
        background: 'black',
        padding: '12px',
        borderRadius: '8px',
        flex: 1
      }}
    >
      {renderContent()}
    </Box>
  );
};
