import { Box, Center, Grid, GridItem, Spinner, Text } from '@chakra-ui/react';
import React from 'react';
import { useGetNftMarketplaceOffersReceived } from '@app/hooks/nft/useNftMarketplaceOffersReceived';
import { ReceivedOfferRow } from '@app/screens/nft-marketplace/SpNftOffersModal/ReceivedOfferRow';
import styles from '../ReceivedOfferRow/ReceivedOfferRow.module.scss';
import { useMedia } from 'react-use';

export const OffersReceivedList = () => {
  const { data, isLoading } = useGetNftMarketplaceOffersReceived();
  const isMobile = useMedia('(max-width: 960px)', false);

  function renderContent() {
    if (isLoading) {
      return (
        <Center height="100%">
          <Spinner color="orange" width={12} height={12} />
        </Center>
      );
    }

    if (!data || !data.length) {
      return (
        <Center height="100%">
          <Text>No offers were received yet</Text>
        </Center>
      );
    }

    function renderHeader() {
      if (!isMobile) {
        return (
          <Grid className={styles.tableHeader}>
            <GridItem>
              <Text fontSize="12px">Pool</Text>
            </GridItem>
            <GridItem display="flex" justifyContent="flex-end">
              <Text fontSize="12px">Vault</Text>
            </GridItem>
            <GridItem>
              <Text fontSize="12px">Properties</Text>
            </GridItem>
            <GridItem>
              <Text fontSize="12px">Auction Type</Text>
            </GridItem>
            <GridItem>
              <Text fontSize="12px" color="primaryWhite">
                Amount{' '}
              </Text>
            </GridItem>
            <GridItem>&nbsp;</GridItem>
          </Grid>
        );
      }

      return null;
    }

    return (
      <>
        {renderHeader()}
        {data.map(item => {
          return (
            <ReceivedOfferRow isMobile={isMobile} bid={item} key={item.id} />
          );
        })}
      </>
    );
  }

  return (
    <Box
      className={styles.scrollbar}
      p={3}
      bg="neutral.1000"
      borderRadius={8}
      flex={1}
      overflowY="auto"
      maxHeight="100vh"
    >
      {renderContent()}
    </Box>
  );
};
