import React, { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useConfig } from '@app/config';
import { auctionOptions } from '@app/screens/nft-marketplace/SellNftForm';
import { HStack, VStack } from '@chakra-ui/react';
import { Token } from '@app/types/token';
import { TitleSection } from '@app/screens/nft-marketplace/SpNftOffersModal/RewardNftsList/components/ListNFTForm/components/TitleSection';
import { NFT } from '@thirdweb-dev/sdk';
import { getAllowedCurrencies } from '@app/screens/nft-marketplace/SpNftOffersModal/RewardNftsList/components/ListNFTForm/helpers';
import { SelectAuctionTypeSection } from '@app/screens/nft-marketplace/SpNftOffersModal/RewardNftsList/components/ListNFTForm/components/SelectAuctionTypeSection';
import { CurrencySection } from '@app/screens/nft-marketplace/SpNftOffersModal/RewardNftsList/components/ListNFTForm/components/CurrencySection';
import { PriceSection } from '@app/screens/nft-marketplace/SpNftOffersModal/RewardNftsList/components/ListNFTForm/components/PriceSection';
import { ReserveSection } from '@app/screens/nft-marketplace/SpNftOffersModal/RewardNftsList/components/ListNFTForm/components/ReserveSection/ReserveSection';
import { PeriodSection } from '@app/screens/nft-marketplace/SpNftOffersModal/RewardNftsList/components/ListNFTForm/components/PeriodSection';
import { SellNftSubmitButton } from '@app/screens/nft-marketplace/SellNftForm/SellNftSubmitButton';
import { useQueryClient } from '@tanstack/react-query';
import { useListNFT } from '@app/hooks/nft/useListNft';
import { CancellationWarningSection } from '@app/screens/nft-marketplace/SpNftOffersModal/RewardNftsList/components/ListNFTForm/components/CancellationWarningSection';
import { parseUnits } from '@app/helpers/format';
import { showErrorToast, showSuccessToast } from '@app/components/Toast';
import { useSigner } from '@thirdweb-dev/react';
import { TransactionResultModal } from '@app/components/TransactionResultModal';
import { useNftSpendApproval } from '@app/hooks/nft/useNftSpendApproval';
import { useApproveNFTSpend } from '@app/hooks/nft/useApproveNFTSpend';

type Form = {
  price: string | undefined;
  reserve: string | undefined;
  currency: Token;
  listingType: string;
  dateRange: Date[];
};

interface Props {
  data: NFT;
  contract: string;
  onClose: () => void;
}

export const ListNftForm: FC<Props> = ({ data, contract, onClose }) => {
  const config = useConfig();
  const signer = useSigner();
  const allowedCurrencies = getAllowedCurrencies(config);
  const queryClient = useQueryClient();
  const { loading, error, listNft } = useListNFT();
  const [listingResult, setListingResult] = useState<{
    status: 'success' | 'error';
    title: string;
    message: string;
    txHash?: string;
  } | null>(null);

  const { mutateAsync: approveSpend, isLoading: isApproving } =
    useApproveNFTSpend();
  const { data: isApproved, isLoading: isLoadingApproval } =
    useNftSpendApproval(contract, config?.CONTRACTS.NFT_MARKETPLACE as string);

  const methods = useForm({
    defaultValues: {
      price: undefined,
      reserve: undefined,
      currency: allowedCurrencies[0],
      listingType: auctionOptions[0].value,
      dateRange: []
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange'
  });

  const { handleSubmit } = methods;

  const onSubmit = async (formData: Form) => {
    if (!isApproved) {
      if (signer) {
        try {
          const result = await approveSpend({
            signer,
            contractAddress: contract,
            tokenId: data.metadata.id,
            nftMarketplaceContractAddress: config?.CONTRACTS
              .NFT_MARKETPLACE as string
          });

          if (result?.receipt.transactionHash) {
            showSuccessToast('Approved');
          }
        } catch (e) {
          showErrorToast('Failed to approve');
        }
      }
    } else {
      const { price, reserve, currency, dateRange, listingType } = formData;
      const [start, end] = dateRange;
      let startUnix = 0;
      let endUnix = 0;

      if (start && end) {
        startUnix = Math.round(+start / 1000);
        endUnix = Math.round(+end / 1000);
      }

      try {
        const result = await listNft({
          price: price
            ? parseUnits(price.toString(), currency.decimals).toString()
            : '0',
          reserve: reserve
            ? parseUnits(reserve.toString(), currency.decimals).toString()
            : '0',
          currency: currency.contractAddress,
          start: startUnix,
          end: endUnix,
          tokenId: data.metadata.id,
          nft: contract,
          listingType: Number(listingType),
          isSemiFungible: true,
          amount: 1
        });

        if (!result.receipt && result._raw.errorName) {
          throw new Error(result._raw.errorName);
        }

        setListingResult({
          status: 'success',
          txHash: result.receipt.transactionHash,
          title: 'Success',
          message: 'The NFT is successfully listed'
        });
      } catch (e: any) {
        console.error(e.message);
        setListingResult({
          status: 'error',
          txHash: '',
          title: 'Error',
          message: 'Transaction failed.'
        });
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <VStack
        gap="12px"
        as="form"
        alignItems="flex-start"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <TitleSection data={data} contract={contract} />
        <HStack
          alignItems="flex-start"
          justifyContent="flex-start"
          width="100%"
          gap={4}
        >
          <SelectAuctionTypeSection />
          <CurrencySection />
        </HStack>
        <PriceSection />
        <ReserveSection />
        <PeriodSection />
        <SellNftSubmitButton
          isListingLoading={loading}
          isPositionApprovalLoading={isLoadingApproval}
          isPositionApproved={isApproved}
          isSetApproveLoading={isApproving}
        />
        <CancellationWarningSection />
        <TransactionResultModal
          isOpen={!!listingResult}
          status={listingResult?.status}
          title={listingResult?.title}
          message={listingResult?.message}
          txHash={listingResult?.txHash}
          onClose={async () => {
            setListingResult(null);
            // todo - invalidate own NFTS list
            // todo - invalidate listed NFTs
            onClose();
          }}
        />
      </VStack>
    </FormProvider>
  );
};
