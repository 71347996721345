import { HStack, VStack, Text, Box } from '@chakra-ui/react';
import { TokensLogoSection } from '@app/components/TokensLogoSection';
import React, { FC } from 'react';
import { AmountSection } from '@app/screens/positions/components/SpNftPositionListRowItem/components/AmountSection';
import styles from './NftFormAssetSection.module.scss';
import { PropertiesSection } from '@app/screens/positions/components/SpNftPositionListRowItem/components/PropertiesSection';
import { PositionItem } from '@app/screens/nft-marketplace/SpNftOffersModal/PositionContext/PositionItemContext';
import { NetworkIcon } from '@app/components/NetworkIcon';
import { Mode } from '@thirdweb-dev/chains';

type Props = {
  position: PositionItem;
  isMobile?: boolean;
};

export const NftFormAssetSection: FC<Props> = ({
  position,
  isMobile = false
}) => {
  let {
    data,
    vault,
    lockDetails,
    usdAmount,
    poolAddress,
    tokenId,
    isStakedToNitro,
    tokenDetails
  } = position;

  if (!data || !tokenDetails) {
    return null;
  }

  if (!vault) {
    return null;
  }

  return (
    <VStack alignItems="flex-start" width="100%">
      <Text
        fontWeight="400"
        fontSize="16px"
        color="neutral.300"
        lineHeight="16px"
      >
        Asset
      </Text>
      <HStack
        w="100%"
        bg="neutral.1000"
        padding="8px 8px 8px 16px"
        borderRadius="12px"
        overflow="hidden"
        justifyContent="flex-start"
        gap={3}
      >
        <TokensLogoSection
          token0Symbol={vault.token0.symbol}
          token1Symbol={vault.token1.symbol}
          size="md"
        />
        <VStack alignItems="flex-start">
          <Text fontWeight={400} fontSize="13px" color="neutral.100">
            {vault.token0.symbol}-{vault.token1.symbol}
          </Text>
          <Text fontWeight={400} fontSize="11px" color="neutral.300">
            ID: {tokenId}
          </Text>
        </VStack>
        {!isMobile ? (
          <HStack
            borderRadius="14px"
            backgroundColor="neutral.100"
            padding="5px 8px"
          >
            <NetworkIcon
              w="24px"
              h="24px"
              name={Mode.name}
              iconUrl={Mode.icon.url.replace(
                'ipfs://',
                'https://ipfs.io/ipfs/'
              )}
              imageSize={{ width: '24px', height: '24px' }}
            />
            <Text fontWeight={400} fontSize="16px" color="black">
              Mode
            </Text>
          </HStack>
        ) : null}
        <PropertiesSection
          lockDetails={lockDetails}
          poolAddress={poolAddress}
          tokenId={tokenId}
          isStakedToNitro={isStakedToNitro}
        />
        <Box paddingLeft={isMobile ? '0' : '32px'}>
          <AmountSection
            classes={{ root: styles.root, amount: styles.amount }}
            amount={data.amount}
            usdAmount={usdAmount}
          />
        </Box>
      </HStack>
    </VStack>
  );
};
