import { useMemo } from 'react';
import { AUCTION_TYPES } from '@app/constants/nftMarketplace';
import { formatUnits } from 'ethers/lib/utils';
import { useTokenPriceV4 } from '@app/hooks/token/useTokenPriceV4';
import { NFTMarketplaceListing } from '@app/types/nftMarketplace';
import { Token } from '@app/types/token';
import { useGetBestBidForListing } from '@app/hooks/nft/useGetBestBidForListing';

export const useNftPriceDetails = (
  listing: NFTMarketplaceListing,
  currency: Token
) => {
  const { data } = useGetBestBidForListing(listing.id);

  const currentPrice = useMemo(() => {
    if (listing.type === AUCTION_TYPES.BUY_IT_NOW_WITH_BIDS) {
      if (data) {
        const parsedAmount = data.amount?.toString() ?? 0;

        return parsedAmount && parsedAmount !== '0'
          ? parsedAmount
          : listing.reserve;
      }

      return listing.price;
    }

    if (listing.type === AUCTION_TYPES.ENGLISH_AUCTION) {
      if (!data) {
        return null;
      }

      const parsedAmount = data.amount?.toString() ?? 0;

      return parsedAmount && parsedAmount !== '0'
        ? parsedAmount
        : listing.reserve;
    }

    return listing.price;
  }, [data, listing]);

  const binValue = useMemo(() => {
    if (listing.type !== AUCTION_TYPES.BUY_IT_NOW_WITH_BIDS) {
      return null;
    }

    return formatUnits(listing.price, currency.decimals);
  }, [listing, currency.decimals]);

  const priceUSD = useTokenPriceV4(
    currency?.contractAddress,
    currentPrice && formatUnits(currentPrice, currency.decimals)
  );

  return {
    currentPrice,
    priceUSD,
    binValue
  };
};
