import React, { FC } from 'react';
import { Text, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';
import { ViewFilter } from '@app/hooks/history/useTransactionHistoryNFT';

type Props = {
  purchaseTimestamp: string;
  selectedView: ViewFilter;
};

export const TransactionNftHistoryTimestamp: FC<Props> = ({
  purchaseTimestamp,
  selectedView
}) => {
  return (
    <VStack width="100%" alignItems="flex-start">
      <Text fontSize="12px" lineHeight="12px">
        {`${selectedView === 'purchased' ? 'Purchased at:' : 'Sold at:'}`}
      </Text>
      <Text color="gray.4" fontSize="12px" lineHeight="12px">
        {format(new Date(+purchaseTimestamp * 1000), 'dd MMM yyyy HH:mm')}
      </Text>
    </VStack>
  );
};
