import React, { useMemo } from 'react';
import { HStack, Text, VStack } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { DropdownMenu } from '@app/components/DropdownMenu';
import styles from '@app/screens/nft-marketplace/SellNftForm/SellNftForm.module.scss';
import { auctionOptions } from '@app/screens/nft-marketplace/SellNftForm';
import { useConfig } from '@app/config';
import { getAllowedCurrencies } from '@app/screens/nft-marketplace/SpNftOffersModal/RewardNftsList/components/ListNFTForm/helpers';

export const SelectAuctionTypeSection = () => {
  const { reset, control } = useFormContext();

  const config = useConfig();
  const allowedCurrencies = getAllowedCurrencies(config);

  const defaultValues = useMemo(
    () => ({
      price: undefined,
      reserve: undefined,
      currency: allowedCurrencies[0],
      listingType: auctionOptions[0].value,
      dateRange: []
    }),
    [allowedCurrencies]
  );

  return (
    <VStack width="100%" alignItems="flex-start">
      <HStack alignItems="center">
        <Text fontSize="14px" color="neutral.300">
          Select Auction type
        </Text>
      </HStack>
      <Controller
        name="listingType"
        control={control}
        render={({ field: { onChange, value } }) => (
          <DropdownMenu
            items={auctionOptions}
            onSelect={selectedValue => {
              reset({
                ...defaultValues,
                listingType: selectedValue
              });
            }}
            selected={value}
            classes={{
              componentContainer: styles.componentContainer,
              headingContainer: styles.headingContainer,
              heading: styles.auctionTypeDropdownHeading,
              dropdownContainer: styles.auctionTypeDropdownContainer
            }}
          />
        )}
      />
    </VStack>
  );
};
